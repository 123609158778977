@keyframes slideFromRight {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0%);
    }
}

.toast {
    border-left: rgb(135, 115, 91) 3px solid!important;
    background-color: burlywood!important;
}

.toast .close {
    float: right;
}

.toast-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.out-toast {
    animation: slideFromLeft 2s ease-in-out;
}

.fade-toast.show {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    animation: slideFromRight 1s ease-in-out;
}